import type { ReactElement } from 'react';
import React from 'react';

import { LoadingIndicator } from '@motorway/mw-highway-code/alpha';

import { applyCypressData } from 'Utilities/helpers';

import styles from './MiragePage.module.scss';

// The extra divs are there cause for some reason one of the wrappers is removing them so you lose the className
const MiragePage = (): ReactElement => (
	<div>
		<div>
			<div className={styles.container}>
				<h1 {...applyCypressData('mirageLoader')}>Loading Mirage</h1>
				<LoadingIndicator />
			</div>
		</div>
	</div>
);

export default MiragePage;
